import * as React from 'react';
import styles from './LayoutPageTab.css';
import { DataPageTemplateRef } from "../../../../../../../dal/model";
import { InlineLoadingIndicatorCom } from "../../../../../../view/common/LoadingIndicator/InlineLoadingIndicatorCom";
import { Msg } from "../../../../../../view/intl";
import { LOAD_TEMPLATE_SELECTOR_CLICK } from "../../../../../TemplateSelector_DEPRECATED/actionTypes";
import { openAddLayoutPageTemplateSelectorAction } from "../../../actions";
import { TooltipPosition } from '../../../../../presentational/Tooltip/constants';
import { DemoTip } from '../../../../../../../demo/modules/tip/DemoTip';

type Props = {
    template: DataPageTemplateRef | null | undefined;
    dispatch: Dispatch;
};

export default class LayoutPageTabFooter extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.onOneComTemplatesClick = this.onOneComTemplatesClick.bind(this);
        this.onTemplateChangeClick = this.onTemplateChangeClick.bind(this);
    }

    // $FlowFixMe: TODO
    onTemplateChangeClick() {
        const
            template = this.props.template,
            { dispatch } = this.props;

        if (!template) return;

        dispatch(openAddLayoutPageTemplateSelectorAction({
            selectedTemplateId: template.id
        }));
    }

    // $FlowFixMe: TODO
    onOneComTemplatesClick() {
        this.props.dispatch({ type: LOAD_TEMPLATE_SELECTOR_CLICK, payload: { isFirstVisit: false } });
    }

    renderChangeTemplate() {
        const template = this.props.template;

        return (
            <div>
                {
                    template
                        ? (
                            <React.Fragment>
                                <Msg
                                    k="common.usedPageTemplate"
                                    params={{ name: template.name }}
                                >{`The new page will use the template {name}.`}</Msg>
                                &nbsp;
                                {/* @ts-ignore */}
                                <DemoTip position={TooltipPosition.BOTTOM}>
                                    <span
                                        onClick={this.onTemplateChangeClick}
                                    >
                                        <Msg className={styles.layoutFooterChangeLabel} k="common.change">Change</Msg>
                                    </span>
                                </DemoTip>
                            </React.Fragment>
                        )
                        : (
                            <InlineLoadingIndicatorCom />
                        )
                }
            </div>
        );
    }

    renderOneComTemplates() {
        return (
            <div className={styles.exploreTemplates}>
                <Msg k="common.explore">Explore</Msg>&nbsp;
                <span
                    onClick={this.onOneComTemplatesClick}
                >
                    <span className="show-in-herold-only">
                        <Msg k="herold.common.heroldTemplates" className={styles.layoutFooterChangeLabel}>
                            Herold templates
                        </Msg>
                    </span>
                    <span className="show-in-checkdomain-only">
                        <Msg k="checkdomain.common.checkdomainTemplates" className={styles.layoutFooterChangeLabel}>
                            Checkdomain templates
                        </Msg>
                    </span>
                    <span className="show-in-hostnet-only">
                        <Msg k="hostnet.common.hostnetTemplates" className={styles.layoutFooterChangeLabel}>
                            Hostnet templates
                        </Msg>
                    </span>
                    <span className="show-in-onecom-only">
                        <Msg k="common.onecomTemplate" className={styles.layoutFooterChangeLabel}>
                            One.com templates
                        </Msg>
                    </span>
                </span>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderChangeTemplate()}
                {this.renderOneComTemplates()}
            </div>
        );
    }
}
