import { PartnerName } from "../../../../server/shared/partners/PartnerName";

export const
    FileManagerMode = {
        FILE_MANAGER: 'FileManager',
        IMAGE_CHOOSER: 'ImageChooser',
        VIDEO_CHOOSER: 'VideoChooser',
    },
    getBrandName = (userBrand: string) => {
        const brandMap = {
            default: "one.com",
            [PartnerName.ONECOM]: "one.com",
            [PartnerName.HOSTNET]: "hostnet",
            [PartnerName.CHECKDOMAIN]: "checkdomain",
            [PartnerName.HEROLD]: "herold"
        };
        return brandMap[userBrand] || brandMap.default;
    },
    siteTemplatesBrandName = "siteTemplates",
    groupOneBrandName = "group.one";
