/* eslint-disable max-len */
/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import registry from '../../../view/oneweb/registry/preview';
import cssResetCode from '../../../view/css-reset-code';
import dropDownFontCss from '../../../view/dropDownFont';
import { isGoogleFont, getFontName } from '../../presentational/AddGoogleFont/utils';
import { getAssetUrl } from '../../../utils/assetUtils';
import { styleToCss } from "../../../utils/styleToCss";
import { BODY_BACKGROUND_CLASS_NAME } from "./constants";
import { getQualityAssetUrlParam } from "../../oneweb/Image/utils";
import type { ImageAsset } from "../../App/flowTypes";
import type { PageRobots } from "../../../../dal/model/DataPageRef";
import { ConsentBannerScript } from './ConsentBanner/ConsentBannerScript';

type HeadProps = {
 title: string;
 description?: null | undefined | string;
 robots: PageRobots;
 minPageWidth: string;
 favIconAsset?: null | undefined | ImageAsset;
 canonicalUrl: string;
 domain: string;
 isServerPreview?: boolean;
 flagPublish?: boolean;
 styleTag: string;
 codeComponents: any;
 templateBackgroundStyle?: null | undefined | Record<string, any>;
 addMobileTags: boolean;
 componentKindsMap?: Record<string, any>;
 shouldRenderMobileHeader: boolean;
 fonts: any;
 pageURL: string;
 siteName: string;
 socialShareAsset: null | undefined | ImageAsset;
 consentBannerData: null | undefined | {
    settings: Record<string, any>;
    config: Record<string, any>;
 },
 templateSelectorPreviewMode?: string,
};

const
    variantsCheat = "100,100italic,200,200italic,300,300italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic,italic,regular", // eslint-disable-line
    getCss = ({ componentKindsMap = {}, shouldRenderMobileHeader, fonts, domain, flagPublish }: HeadProps) => {
        const
            addedCss = {},
            linkTags: React.JSX.Element[] = Object.keys(componentKindsMap).reduce((prev, kind) => {
                const { css } = registry[kind] || {};
                if (css) {
                    css.forEach((url, idx) => {
                        if (!addedCss[url]) {
                            addedCss[url] = true;
                            prev.push(
                                <link key={`css-${kind}${idx}`} rel="stylesheet" type="text/css" href={url} />
                            );
                        }
                    });
                }
                return prev;
            }, [] as React.JSX.Element[]);

        if (shouldRenderMobileHeader) {
            linkTags.push(
                <link
                    key="mobile-menu"
                    rel="stylesheet"
                    type="text/css"
                    href="/renderStatic/mobileMenu/mobileMenu.css"
                />
            );
        }

        if (fonts) {
            fonts.forEach((font, idx) => {
                if (isGoogleFont(font)) {
                    const googleFontUrl = (
                        'https://fonts.googleapis.com/css' +
                            '?family=' + encodeURIComponent(getFontName(font) + ':' + variantsCheat) +
                            '&subset=all'
                    );

                    let href;
                    if (flagPublish) {
                        href = `/api/v1/${domain}/webspace/onewebstatic/google-fonts.php?url=${encodeURIComponent(googleFontUrl)}`;
                    } else {
                        href = googleFontUrl;
                    }

                    linkTags.push(
                        <link
                            key={`font-${idx}`}
                            href={href}
                            rel="stylesheet"
                            type="text/css"
                        />
                    );
                }
            });
        }

        return linkTags;
    },
    getMinPageWidthStyles = minPageWidth => {
        return `
        @media (max-width: 650px) { body { min-width: 'initial' } body.desktopV { min-width: ${minPageWidth}px } }
        @media (min-width: 651px) { body { min-width: ${minPageWidth}px } body.mobileV { min-width: initial } }`;
    },
    getFaviconUrlFactory = (favIconAsset: ImageAsset) => (resize) => {
        return getAssetUrl(favIconAsset, { resize: `${resize},${resize}`, ignoreAspectRatio: true });
    };

const renderFavicons = (favIconAsset: ImageAsset) => {
    const { width, height } = favIconAsset,
        getFaviconUrl = getFaviconUrlFactory(favIconAsset),
        linkArray = [<link rel="shortcut icon" sizes="16x16" href={getFaviconUrl(16)} />];

    if (width >= 32 && height >= 32) linkArray.push(<link rel="icon" sizes="32x32" href={getFaviconUrl(32)} />);
    if (width >= 57 && height >= 57) linkArray.push(<link rel="apple-touch-icon" href={getFaviconUrl(57)} />);
    if (width >= 60 && height >= 60) linkArray.push(<link rel="apple-touch-icon" sizes="60x60" href={getFaviconUrl(60)} />);
    if (width >= 64 && height >= 64) linkArray.push(<link rel="icon" sizes="64x64" href={getFaviconUrl(64)} />);
    if (width >= 72 && height >= 72) linkArray.push(<link rel="apple-touch-icon" sizes="72x72" href={getFaviconUrl(72)} />);
    if (width >= 76 && height >= 76) linkArray.push(<link rel="apple-touch-icon" sizes="76x76" href={getFaviconUrl(76)} />);
    if (width >= 96 && height >= 96) linkArray.push(<link rel="icon" type="image/png" sizes="96x96" href={getFaviconUrl(96)} />);
    if (width >= 114 && height >= 114) linkArray.push(<link rel="apple-touch-icon" sizes="114x114" href={getFaviconUrl(114)} />);
    if (width >= 120 && height >= 120) linkArray.push(<link rel="apple-touch-icon" sizes="120x120" href={getFaviconUrl(120)} />);
    if (width >= 144 && height >= 144) linkArray.push(<link rel="apple-touch-icon" sizes="144x144" href={getFaviconUrl(144)} />);
    if (width >= 152 && height >= 152) linkArray.push(<link rel="apple-touch-icon" sizes="152x152" href={getFaviconUrl(152)} />);
    if (width >= 160 && height >= 160) linkArray.push(<link rel="icon" type="image/png" sizes="160x160" href={getFaviconUrl(160)} />);
    if (width >= 180 && height >= 180) linkArray.push(<link rel="apple-touch-icon" sizes="180x180" href={getFaviconUrl(180)} />);
    if (width >= 196 && height >= 196) linkArray.push(<link rel="icon" type="image/png" sizes="196x196" href={getFaviconUrl(196)} />);

    return (linkArray);
};

const getOpenGraphMetaTags = ({ description, title, siteName, pageURL, socialShareAsset }: HeadProps) => {
    // type common for all pages
    const ogMetaTags = [
        <meta property="og:type" content="website" />
    ];

    if (description) {
        ogMetaTags.push(<meta property="og:description" content={description} />);
    }
    ogMetaTags.push(<meta property="og:title" content={title} />);
    ogMetaTags.push(<meta property="og:site_name" content={siteName} />);
    ogMetaTags.push(<meta property="og:url" content={pageURL} />);

    if (socialShareAsset) {
        const assetQuery = getQualityAssetUrlParam(socialShareAsset);
        ogMetaTags.push(<meta property="og:image" content={getAssetUrl(socialShareAsset, assetQuery)} />);

        // og:image:type - A MIME type for this image.
        ogMetaTags.push(<meta property="og:image:type" content={socialShareAsset.contentType} />);

        // og:image:width - The number of pixels wide.
        // @ts-ignore
        ogMetaTags.push(<meta property="og:image:width" content={socialShareAsset.width} />);

        // og:image:height - The number of pixels high.
        // @ts-ignore
        ogMetaTags.push(<meta property="og:image:height" content={socialShareAsset.height} />);
    }

    return ogMetaTags;
};

const getTwitterCardsMetaTags = ({ description, title, socialShareAsset }: HeadProps) => {
    /*
    As per https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/getting-started#opengraph. Twitter cards can interoperate with open graph tags, thus extracting the same information for generating card via the open graph tags.

    https://stackoverflow.com/questions/66810391/twitter-open-graph-image-not-showing
    */
    const twitterMetaTags = [
        <meta name="twitter:card" content="summary" />,
        <meta name="twitter:title" content={title} />,
        <meta name="twitter:image:alt" content={title} />,
    ];

    if (description) {
        twitterMetaTags.push(<meta name="twitter:description" content={description} />);
    }

    if (socialShareAsset) {
        const assetQuery = getQualityAssetUrlParam(socialShareAsset);
        twitterMetaTags.push(<meta name="twitter:image" content={getAssetUrl(socialShareAsset, assetQuery)} />);
    }

    return twitterMetaTags;
};

export default (props: HeadProps) => {
    return (
        <head>
            <title>{props.title}</title>
            {props.description && <meta name="description" content={props.description} />}
            <meta name="robots" content={props.robots} />
            <meta name="generator" content="One.com Web Editor" />
            <meta httpEquiv="Cache-Control" content="must-revalidate, max-age=0, public" />
            <meta httpEquiv="Expires" content="-1" />
            <meta
                name="viewport"
                // Do not modify this string as it is checked for this exact match when rendering published page
                content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=3.0, user-scalable=yes"
                // @ts-ignore
                // eslint-disable-next-line react/no-unknown-property
                minpagewidth={props.minPageWidth}
                // Do not use boolean value as it is not updating in some cases
                // @ts-ignore
                // eslint-disable-next-line react/no-unknown-property
                renderMobileView={props.addMobileTags ? "true" : "false"}
            />
            <meta name="MobileOptimized" content="320" />
            <meta name="HandheldFriendly" content="True" />
            <meta name="format-detection" content="telephone=no" />
            {getOpenGraphMetaTags(props)}
            {getTwitterCardsMetaTags(props)}
            {props.favIconAsset && renderFavicons(props.favIconAsset)}
            {/* Do not change the order of rel and href attribute for canonical url. See server/lib/handlers/publish/handleLinkUpdates.js for reason. */}
            {props.canonicalUrl.length > 0 && <link rel="canonical" href={props.canonicalUrl} />}
            <style type="text/css" dangerouslySetInnerHTML={{ __html: cssResetCode }} />
            <style type="text/css" dangerouslySetInnerHTML={{ __html: dropDownFontCss }} />
            <style type="text/css" dangerouslySetInnerHTML={{ __html: props.styleTag }} />
            <style type="text/css" dangerouslySetInnerHTML={{ __html: getMinPageWidthStyles(props.minPageWidth) }} />
            {getCss(props)}
            <link rel="stylesheet" type="text/css" href="/wbtgen/preview.css" />
            {props.templateBackgroundStyle && (
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html: `
                        .${BODY_BACKGROUND_CLASS_NAME} {
                            ${styleToCss(props.templateBackgroundStyle)}
                        }
                    `
                    }}
                />
            )}
            {
                /* NOTE: make code component always at the bottom of Header.
                Else, it will push down all items to body if it has a DIV wrapper around it. */
            }
            <ConsentBannerScript consentBannerData={props.consentBannerData} />
            {/* The below code tells OS to not add a link to Privacy policy as it will be added by WSB */}
            <script type="text/javascript">window.WSB_PRIVACY_POLICY_PUBLISHED = true;</script>
            {
                props.isServerPreview ?
                    <script src="/renderStatic/linkOpener/attachOnClickHandlers.js" /> :
                    <script src="/renderStatic/linkOpener/attachClientPreviewOnClickHandlers.js" />
            }
            <script src="/renderStatic/mobileZoom.js" />
            {props.codeComponents}
        </head>
    );
};
